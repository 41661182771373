/*
モジュール系おまとめファイル
*/

// ヘッダー
@import "header";

// フッター
@import "footer";

// グローバルナビゲーション
@import "navigation";

 // ページトップに戻る
@import "pageTop";

 // ボタン
@import "button";

//テーブル
@import "table";

//ぱんくず
@import "topicspath";

//見出し
@import "heading";

//テキスト
@import "text";

//リンク
@import "link";

//背景色
@import "background";

//リスト
@import "list";

//ボックス
@import "box";

//ラベル
@import "label";

//コンバージョンエリア
@import "cv-area";

//ニュース
@import "news";

//フォーム
@import "form";

//ページネーション
@import "pagenation";

//アコーディオンメニュー
@import "accordion";
