$pink_box: #F284A6;

.box{
    background: #fff;
    border: 3px solid #cecece;
    margin-bottom: 40px;
    position: relative;

    .boxwrap{
        box-shadow: 10px 10px 0 inset #fff;
        padding: 30px;
        section{
            margin-bottom: 60px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &:last-child{
        margin-bottom: 0;
    }

    //サブボックス
    &.sub{
        box-shadow: none!important;
        background: #fff!important;;
        .boxwrap{
            box-shadow: none!important;
            position: relative;
            strong.ttl{
                position: absolute;
                display: inline-block;
                max-width: 90%;
                padding: 10px 20px;
                background: #000;
                color: #fff;
                letter-spacing: 3px;
                font-weight: 500;
                left: 40px;
                top: -20px;
                @include mq-max(xs){
                  left: 15px;
                }
            }
        }
        &.blue{
            strong.ttl{
                background: $keyColor;
            }
        }
        &.pink{
            strong.ttl{
                background: $pink_box;
            }
        }
    }


    //下矢印付き
    &.arrow{
        margin-top: 80px;
        &:before{
            width: 0;
            height: 0;
            border-left: 60px solid transparent;
            border-right: 60px solid transparent;
            border-top: 40px solid $keyColor;
            display: block;
            content: "";
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: -20px;
            animation: vertical 1s ease-in-out infinite alternate;
        }
        &.blue{
            &::before{
                border-top: 40px solid $keyColor;
            }
        }
        &.pink{
            &::before{
                border-top: 40px solid $pink_box;
            }
        }
    }

    @keyframes horizontal {
        0% { transform:translateX( -3px); }
      100% { transform:translateX(  0px); }
    }
    @keyframes vertical {
        0% { transform:translateY(-10px); }
      100% { transform:translateY(  0px); }
    }


    //色の設定

    &.blue,&.pink{
        padding: 0;
        .boxwrap{
            padding: 60px;
            @include mq-max(xs){
              padding: 60px 30px;
            }
        }
    }

    &.blue{
        border-color: $keyColor;
        background: $lightBlue;
        box-shadow: 10px 10px 0 $lightBlue;
    }

    &.pink{
        border-color: $pink_box;
        background: #FFFEF3;
        box-shadow: 10px 10px 0 #FFFEF3;
    }

    &.bordernone{
      border: 0;
      background: transparent;
      box-shadow: 0 0 0 ;
    }


}






.over-box{
  position: relative;
  margin-top: 160px;
  margin-bottom: 180px;
  @include mq-max(lg){
    margin-bottom: 50px;
  }
  @include mq-max(md){
    margin-top: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .desc-box{
    width: 66%;
    border: solid 1px #C7E1F0;
      @include mq-max(lg){
        width: 60%;
      }
      @include mq-max(md){
        width: 100%;
        padding: 60px 30px 30px 30px;
        -ms-flex-order: 2;
        order: 2;
        margin-top: -50px;
      }
      &.box-right{
        padding: 120px 80px 120px 220px;
        margin-left: 0;
        // margin-right: -75px;
        float: right;
        @include mq-max(lg){
          padding: 80px 80px 80px 80px;
          p{
            padding: 0;
          }
        }
        @include mq-max(md){
          padding: 60px 30px 30px 30px;
          margin-right: 0;
        }
        &.small{
          padding: 80px 60px 80px 160px;
          @include mq-max(lg){
            padding: 80px 60px 60px 60px;
          }
          @include mq-max(md){
            padding: 60px 40px 40px 40px;
          }
          @include mq-max(sm){
            padding: 60px 20px 20px 20px;
          }
        }
        p{
          // padding-right: 80px;
        }
      }
      &.box-left{
        padding: 120px 220px 120px 160px;
        float: left;
        // margin-left: -75px;
        @include mq-max(lg){
          padding: 80px 80px 80px 80px;
        }
        @include mq-max(md){
          padding: 60px 30px 30px 30px;
          margin-left: 0;
        }
        &.small{
          padding: 80px 160px 80px 80px;
          @include mq-max(lg){
          padding: 80px 60px 60px 60px;
          }
          @include mq-max(md){
          padding: 60px 40px 40px 40px;
          }
          @include mq-max(sm){
          padding: 60px 20px 20px 20px;
          }
        }
      }


    h1,h2,h3,h4,h5{
      font-size: 3.5rem;
      margin-bottom: 24px;
      text-align: left;
      @include mq-max(lg){
        font-size: 3rem;
      }

    }

    p{
      margin-bottom: 20px;
    }

  }

  > img:last-child{
    position: absolute;
    top: -98px;
    z-index: 0;
    @include mq-max(lg){
      width: 50%;
      top: -50px;
    }
    @include mq-max(md){
      width: 50%;
      height: auto;
      margin: 15px auto;
      position: relative;
      align-self: flex-start;
      order:1;
      z-index: 1;
      position: relative;
      top: 0;
    }
    @include mq-max(xs){
      width: 100%;
    }

    &.img-right{
      right: 0;
      @include mq-max(md){
        margin-right: -15px;
        // max-width: 100%;
        // width: auto;
      }
    }
    &.img-left{
      left: 0;
      @include mq-max(md){
        margin-left: -15px;
        // max-width: 100%;
        // width: auto;
      }
    }
  }
}
