
/*-----------------------------------------
   label
----------------------------------------- */
/* ラベル(白黒) */

/* 両端が丸いラベル */
.label {
	display: inline-block;
	background-color: $keyColor;
  color: #FFF;
  width: 120px;
	line-height: 24px;
	letter-spacing: 0.1rem;
  font-size: 1.2rem;
  text-align: center;



  $green: #BBF284;
  $pink: #F284A6;
  $gold: #F2D084;
  $ultramarine: #84AAF2;

  &.green {
    background-color: $green;
  }
  &.pink {
    background-color: $pink;
  }
  &.gold {
    background-color: $gold;
  }
  &.ultramarine {
    background-color: $ultramarine;
  }


/********************************

    ラベルカラー

********************************/

//お知らせ
&.news{
  background-color: $green;
}
&.media{
  background-color: $pink;
}
&.seminar{
  background-color: $gold;
}

//お客様
&.nourin{}
&.kensetsu{}
&.seizou{}
&.denki{
  width: 180px !important;
}
&.info{}
&.unyu{}
&.oroshiuri{}
&.finance{}
&.hudousan{}
&.drink{}
&.medical{}
&.kaigo{}
&.education{}
&.hukugou{}
&.service{}




  &.line{
    text-align: left;
    color: #000;
    width: auto;
    border-left-width: 10px;
    border-left-style: solid;
    background: transparent;
    padding-left: 10px;

      &.green {
        border-color: $green;
      }
      &.pink {
        border-color: $pink;
      }
      &.gold {
        border-color: $gold;
      }
      &.ultramarine {
        border-color: $ultramarine;
      }

  }

}