
  .btn{
      display: inline-block;
      width: 280px;
      max-width: 100%;
      padding: 20px 40px;
      border: 1px solid $keyColor;
      text-align: center;
      margin: 5px 0 0 0;
      position: relative;
      background: $keyColor;
      color: #fff!important;
      transition: background-color .2s,
      color .2s;
      font-weight: 600;

      &:hover{
        background: #fff;
        color: $keyColor!important;
      }

      &.auto{
        width: auto;
      }

      &.small{
        padding: 0;
        width: 214px;
        height: 28px;
        font-size: 1.2rem;
        line-height: 21px;
      }

      &.large{
        width: 480px;
        height: 100px;
        padding: 0;
        font-size: 2rem;
        line-height: 100px;
      }


      //アイコン共通
      &:after{
        position: absolute;
        right: 15px;
        top: 25px;
      }

      &[href$=".pdf"]{
          &:after {
            color: #fff!important;
        }
        &:hover:after {
          color: $keyColor!important;
        }
      }

  }

  .bg.blue{

  .btn{
      background: #fff;
      color: $keyColor!important;
      border: 1px solid #fff;

      &:hover{
        color: #fff!important;
        border: 1px solid #fff;
        background: $keyColor;
      }
    }
  }



  .btnBox{
    margin: 40px 0;
    text-align: center;
  }
