//サイトレイアウト構造を記述

#container{}
#main{}

//text-align
.taC{
  text-align: center !important;
}
.taL{
  text-align: left !important;
}
.taR{
  text-align: right !important;
}

//文章に対して
.txt{
  text-align: center;
  @include mq-max(xs){
    text-align: left;
  }
}

.only-xs{
  display: none !important;
  @include mq-max(xs){
    display: block !important;
  }
}

.no-xs{
  display: block !important;
  @include mq-max(xs){
    display: none !important;
  }
}//スマホの時のみ非表示

.only-sp{
  display: none !important;
  @include mq-max(sm){
    display: block !important;
  }
}

.only-md{
  display: none !important;
  @include mq-max(sm){
    display: block !important;
  }
  @include mq-max(xs){
    display: none !important;
  }
}//tabletのみ

.only-pc{
  display: block !important;
  @include mq-max(sm){
    display: none !important;
  }
}
