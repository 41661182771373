//contactform7用のカスタマイズCSS


//* //////////////////////////////////////////////////////////////////////////////////////////
//
//    初期設定
//
//     ※49行目あたりにボタンの classを記述するところがあります※※※
//
//* //////////////////////////////////////////////////////////////////////////////////////////


//テーブル
$border-table:   1px solid #ccc;//テーブルのボーダー
$th-bgColor-table:   #eee;//テーブルのｔｈ背景
$th-txtColor-table:  #333;//テーブルのｔｈの文字色
$table-padding: 15px;//テーブルの余白

//色の設定
$main-color: #1369b6;//サイトのキーカラー

//入力項目の設定
$border-radius:  3px;//全体の角丸具合（なしは0px）
$border-parts:   #ccc;//入力フィールドのボーダー色
$padding-parts:  10px;//入力フィールドの余白
$attentionColor: #c82e2e;//必須アイコン等（初期値は赤）


//↓このIDに囲まれたcontactform7って設定してます
#page div.wpcf7 {
  padding: 0;
  border: none;
  background: transparent;

  form{
    margin: 0;
  }

  *{
    box-sizing: border-box;
  }

  //エラー文字
  .wpcf7-not-valid-tip{
    color: $attentionColor;
  }


//* //////////////////////////////////////////////////////////////////////////////////////////
//
//        //送信ボタンに使いたいボタンclass名を書いてください
//
//* //////////////////////////////////////////////////////////////////////////////////////////


input[type=button],
input[type=submit]{
  display: inline-block;
  text-align: center;
  padding: 20px 40px;
  margin: 15px;
  height: auto;
  text-decoration: none;
  transition: 0.2s all ease;
  background:$main-color;
  border-radius: $border-radius;
  color: #fff;
  font-weight: bold;
  width: auto;
  font-size: 140%;
    @include mq-max(md){
      padding: 20px 0;
      display: block;
      width: 100%;
    }

  &:hover{
    opacity: 0.7;
  }

}

input[type=button]{
  background: #ccc;
  color: #444;
  padding: 15px 15px;
  max-width: 200px;
  font-size: 100%;
    @include mq-max(md){
      padding: 15px 0;
    }
}

//* //////////////////////////////////////////////////////////////////////////////////////////
//
//        ステップの設定
//
//* //////////////////////////////////////////////////////////////////////////////////////////


  .step{
    margin-bottom: 20px;
    display: -webkit-flex;
    display: -ms-flex;
    -js-display: flex;
    display: flex;
      @include mq-max(md){
        display: none;
      }
    ul{
      flex-direction: row;
    }
    ul{
      width: 100%;
      display: -webkit-flex;
      display: -ms-flex;
      -js-display: flex;
      display: flex;
      margin:0 0 20px 0 ;
      li{
        flex-direction: row;
        width: 33%;
        min-height: 50px;
        line-height: 50px;
        margin: 1%;
        background: #cecece;
        padding: 0 20px;
        position: relative;
        text-align: center;
        &:after,&:before{
          content: "";
          position: absolute;
          top: 0;
          width: 0;
        }
        &:after{
          right: -10px;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 10px solid #cecece;
        }
        &:before{
            left: 0;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-left: 10px solid #ffffff;
        }
        &:first-child{
          &:before{
            display: none;
          }
        }
      }
    }

      ul{
        li.active{
          background: $main-color;
          color: $main-color;
          color: #fff;
          &:after{
            border-left-color: $main-color;
          }
        }
      }
  }

  //* //////////////////////////////////////////////////////////////////////////////////////////
  //
  //        テーブルの設定
  //
  //* //////////////////////////////////////////////////////////////////////////////////////////

  table{
    background: #fff;
    width: 100%;
    @include mq-max(md){
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
    }
    th,td{
      border:$border-table;
      padding: $table-padding;
        @include mq-max(md){
          display: block;
        }
    }
    th{
      width: 25%;
      background: $th-bgColor-table;
      color: $th-txtColor-table;
      @include mq-max(md){
        width: 100%;
      }
    }
  }

  //* //////////////////////////////////////////////////////////////////////////////////////////
  //
  //        アイコン
  //
  //* //////////////////////////////////////////////////////////////////////////////////////////
  //必須
  span.required{
    font-size: 80%;
    background: $attentionColor;
    color: #fff;
    display: inline-block;
    padding: 0 5px;
    margin: 0 2px;
    border-radius: $border-radius;
  }

  //* //////////////////////////////////////////////////////////////////////////////////////////
  //
  //        インプットや入力項目
  //
  //* //////////////////////////////////////////////////////////////////////////////////////////
  button, input, select, textarea {
      background-color: transparent;
      border-style: none;
      color: inherit;
      box-sizing: border-box;
      @include mq-max(md){
        font-size: 16px; // iOSで入力エリアが拡大されてしまわないよう調整
      }
  }

  input,textarea,select{
    border: 1px solid $border-parts;
    border-radius: $border-radius;
    padding: $padding-parts;
    background: #fff;
    &.wpcf7-not-valid{
      border: 1px solid #d53030;
    background: #ffecec;
    }
    &.wpcf7c-conf{
      background-color: #fff !important;
      border: none !important;
    }
  }

  input[type=text],input[type=tel],input[type=number],input[type=email]{
    width: 40%;
      @include mq-max(md){
          width: 100%;
      }
    &.small{
        width: 30%;
          @include mq-max(md){
              width: 100%;
          }
    }
    &.medium{
        width: 60%;
          @include mq-max(md){
              width: 100%;
          }
    }
    &.long{
        width: 100%;
    }
  }

   textarea{
     width: 100%;
     height: 150px;
   }

   select{
     width: 50%
   }

  textarea{
    width: 100%;
  }

}
