ul,ol {
    li {
      line-height: 1.8;
    }
    &.list_type_normal {
      list-style-type: none;
      li {
        position: relative;
        padding-left: 1.5em;
        font-weight: 500;
        margin-bottom: 16px;
        line-height: 1.5em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  ol.list_type_normal {
    li{
        padding-left: 1.5em;
        list-style: decimal;
        list-style-position: inside;
        text-indent: -1.5em;
    }
  }

  ul{
    &.list_type_normal {
      li{
          &:before {
              content: "";
              display: block;
              position: absolute;
              border-radius: 50%;
              width: 5px;
              height: 5px;
              top: 9px;
              left: .75em;
              background-color: $keyColor;
          }
      }
      &.ico_check{
          li{
            font-size: 1.6rem;
            font-weight: bold;
              &:before{
                content: url(/inc/image/common/ico_check.png);
                display: inline-block;
                vertical-align: middle;
                display: block;
                position: absolute;
                transform: scale(0.5);
                background: transparent;
                top: 2px;
                left: 0;
            }
          }
          &.inline li{
            @include mq-max(md){
              display: block;
            }
            &:after{
              content: "／";
              color: #ccc;
              margin-left: 10px;
              margin-right: 10px;
              @include mq-max(sm){
                display: none;
              }
            }
          }
      }
    }
  }

  ul.inline{
    li{
      display: inline-block;
      margin-right: 10px;
    }
  }

  /* ※がある注意書きに使用 */

  ul.list_type_attention li {
    margin-left: 1em;
    text-indent: -1em;
  }

  /*定義リスト*/

  .dl_info dl {
    clear: both;
    line-height: 1.6em;
    dt {
      width: 4em;
      float: left;
    }
    dd {
      overflow: hidden;
      text-indent: -1em;
      padding-left: 1em;
      zoom: 1;
    }
  }

//  チェックリスト
  div.checklist {
    margin-bottom: 80px;

    section{
        margin: 0;
        padding: 0;
        max-width: 100%;
    }

    ul{
        background: #fff;
        width: 950px;
        max-width: 100%;
        padding: 40px 0;
        margin: 0 auto;
        li {
            width: 800px;
            max-width: 90%;
            font-size: 1.9rem;
            margin: 0 auto 30px;
            padding-left: 45px;
            position: relative;
            font-weight: 600;
            &:before{
                content: url(/inc/image/common/ico_check.png);
                display: inline-block;
                vertical-align: middle;
                display: block;
                position: absolute;
                width: 35px;
                height: 35px;
                top: 2px;
                left: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
        }
    }

  }
