.cv-sodankai{
  width: 100%;
  position: relative;
  padding-top:60px;
  padding-bottom: 70px;
  background:url(/inc/image/common/pic_cv-sodankai.jpg) no-repeat center;
  background-size: cover;
  position: relative;


  @include mq-max(sm){
    padding-left: 20px;
  }
  .cv-border{
    width: 670px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    border:solid 5px $keyColor;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .cv-box{
    margin-top: -20px;
    margin-left: -20px;
    padding: 30px 80px 55px;
    background-color: $keyColor;
    @include mq-max(sm){
      padding: 35px 25px;
    }
    h1,h2,h3,h4,h5{
      letter-spacing: 0.3em;
      font-size: 2.5rem;
      color: #fff;
      margin-bottom: 15px;
    }
    p{
      color: #fff;
    }
    .btn{
      background-color: #fff;
      color: $keyColor !important;
      width: 100%;
      margin-top: 20px;
      &:hover{
        background-color: $keyColor;
        color: #fff !important;
        border-color:#fff;
      }
      @include mq-max(sm){
        padding: 20px;
      }
    }
  }
}


* + .cv-sodankai {
  margin-bottom: 100px;
}