// 下層全体に適用するCSS

#lowerCaption{
  section{
    width: 100%;
    height: 310px;
    position: relative;
    h1.lowerTitle{
      font-size: 5.2rem;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      @include mq-max(sm){
        font-size: 3.2rem;
        width: 80%;
        text-align: center;
      }
      @include mq-max(xs){
        font-size: 2.6rem;
        width: 90%;
      }
      span{
        display: block;
        font-size: 1.8rem;
        text-align: center;
        margin-top: 10px;
        @include mq-max(xs){
          font-size: 1.6rem;
        }
      }
    }
  }

  //背景設定
  &.bg_default{
    background: url(/inc/image/common/bg_h1.jpg) no-repeat center center;
    position: relative;
    text-shadow: 0 0 9px rgba(0, 76, 123, 0.25);
    &:before{
      content: "";
      display: block;
      background: #004C7B;
      opacity: 0.25;
      width: 100%;
      height: 310px;
      position: absolute;
      top: 0;
      left: 0;
    }

    //選ばれる理由
    #reason &{
      background: url(/inc/image/reason/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //私たちについて
    #about &{
      background: url(/inc/image/about/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //助成⾦申請代⾏サービス
    #service_joseikin &{
      background: url(/inc/image/service/joseikin/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //その他のサービス
    #service_other &{
      background: url(/inc/image/service/other/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //人事労務サービス
    #service_outside &{
      background: url(/inc/image/service/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //アドバイザリー
    #advisory &{
      background: url(/inc/image/service/advisory/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //人事労務コンサルティング
    #planning &{
      background: url(/inc/image/service/planning/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //アウトソーシング
    #outsourcing &{
      background: url(/inc/image/service/outsourcing/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //企業の成長や規模に合わせた人事部のサポート
    #stage &{
      background: url(/inc/image/stage/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //初めて社労士に依頼する方へ
    #consult_request &{
      background: url(/inc/image/consult/request/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //社労⼠を変更したい⽅へ
    #consult_change &{
      background: url(/inc/image/consult/change/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //無料相談会
    #consult &{
      background: url(/inc/image/consult/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //採用情報
    #recruit & , #recruit_info &{
      background: url(/inc/image/recruit/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //プライバシーポリシー
    #privacy &{
      background: url(/inc/image/privacy/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //お客様紹介一覧 / 詳細
    #client_list & , #client &{
      background: url(/inc/image/client/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //ニュース一覧 / 詳細
    #news_list & , #news &{
      background: url(/inc/image/news/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

    //ブログ一覧 / 詳細
    #blog_list & , #blog &{
      background: url(/inc/image/blog/bg_mainImg.jpg) no-repeat center center;
      background-size: cover;
    }

  }
}
