//全体共通で使うCSS定義

html{
  font-size: 62.5%;
  // font-family: $NotoSansJP;
  font-family: 'Noto Sans JP', sans-serif;
  //SPメニュー開いた時
  &.opened{
    // overflow: hidden !important;
    // height: 100%;
    // // body{
    // //   overflow: hidden !important;
    // //   height: 100%;
    // // }
    #pageTop{display: none !important;}
  }
}

body{
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  font-size: 1.4rem; //14px
}

#main{
  @include mq-max(sm){
    margin-top: $headerHeight;//headerがfixedのため
  }
}

a{
  text-decoration: none;
  color: #000;
}

p{
  font-size: 1.6rem;
  margin: 1.5em 0;
  line-height: 1.5;
}

strong{
  font-size: 105%;
  padding: 0 5px;
  line-height: 2;
  background: linear-gradient(transparent 75%, #FFE945 75%);
  &.bigger{
    font-size: 160%;
    color: $keyColor;
  }
  &.orange{
  background: linear-gradient(transparent 75%, #FF8D45 75%);
  }
}


.section{
  width: 100%;
  // margin: 0 0 130px;
  letter-spacing: 0.08em;

  &>section{
    max-width: $contentWidth;
    margin: 0 auto $bottom_margin_section;
    &.width-small{
      width: 800px;
      max-width: 100%;
    }
    &:last-child{
      margin-bottom: 0;
    }
    @include mq-max(lg){
      width: 100%;
      padding: 0 100px;
    }
    @include mq-max(md){
      width: 100%;
      padding: 0 40px;
    }
    @include mq-max(sm){
      width: 100%;
      padding: 0 6.4%;
    }
    &>section{//h3
      margin-top: $bottom_margin_section - 30px;
      @include mq-max(sm){
        margin-top: 50px;
      }
      &>section{//h4
        margin-top: $bottom_margin_section - 30px -20px;
        margin-bottom:  $bottom_margin_section - 30px -20px;
        &>section{//h5
          margin-top:  $bottom_margin_section - 30px -20px -20px;
          margin-bottom: 0;
        }
      }

    }
  }
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}
