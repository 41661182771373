table{
    &.tableStyle{
        width: 100%;
        border-top: 1px solid $keyColor;

        tr{
            display: block;
            border-bottom: 1px solid $keyColor;
        }

        th,td{
            padding: 15px;
            font-size: 1.6rem;
            margin: 1.5em 0;
            line-height: 1.5;
        }

        th{
            font-weight: 600;
            color: $keyColor;
            text-align: left;
            width: 12em;
            @include mq-max(sm){
                width: 100%;
                white-space: nowrap;
                display: block;
                border-bottom: dotted 1px;
                margin: 0;
            }
        }
    }

}
