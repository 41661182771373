//アーカイブページのページネーション
.pagenation{
  // display: flex;
  // justify-content: center;
  // @include mq-max(sm){
  //   flex-direction: column;
  // }
  // a{
  //   margin: 0 calc(25px / 2);
  //   @include mq-max(sm){
  //     margin: 10px auto;
  //     display: block;
  //   }
  // }

  text-align: center;
  margin-top: 40px;
  .page-numbers{
    padding: 15px;
    &.current{
      background-color: $keyColor;
      color: #fff;
    }
  }

}