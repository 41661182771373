.section{
  padding: 100px 0;
  @include mq-max(xs){
    padding-top:50px;
    padding-bottom: 30px;
  }
    &.bg{
        &.blue{
            background: $keyColor;
        }
        &.lightblue{
            background: $lightBlue;
        }
        &.gray{
            background: #f1f1f1;
        }
    }
}
