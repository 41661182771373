footer{
  background-color: #F0FBFF;
  margin-top: 100px;
  .footerArea{
    max-width: $contentWidth;
    margin: 0 auto;
    padding: 60px 0 55px;
    @include mq-max(lg){
      width: 100%;
      padding: 60px 100px 55px;
    }
    @include mq-max(md){
      padding-left: 40px;
      padding-right: 40px;
    }
    @include mq-max(sm){
      padding: 31px 6.4%;
    }

    //バナーエリア
    .bnrArea{
      display: flex;
      padding-bottom: 60px;
      border-bottom: 1px solid #fff;
      justify-content: space-between;
      @include mq-max(sm){
        flex-wrap: wrap;
      }
      @include mq-max(xs){
        display: block;
        flex-direction: column;
        border-bottom: none;
      }
      a{
        display: block;
        // margin-left: 30px;
        @include mq-max(sm){
          width: 50%;
          margin-top: 20px;
        }
        @include mq-max(xs){
          display: flex;
          width: 100%;
          margin-left: 0;
          // margin-top: 20px;
        }

        &:first-child{
          // margin-left: 0;
          @include mq-max(xs){
            margin-top: 0;
          }
        }
        img{
          max-width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }
    }


    .footerNavi{
      margin-top: 60px;
      font-size: 1.2rem;
      @include mq-max(sm){
        margin-top: 0;
      }
      .footerList{
        // display: flex;
        // justify-content: space-between;
        @include mq-max(sm){
         display: block;
        }

        //会社情報
        .info{
          @include mq-max(md){
            text-align: center;
          }

          @include mq-max(sm){
            // max-width: 210px;
            // margin: 0 auto;
            padding: 30px 0;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
          }

          img{
            width: 145px;
            display: inline-block;
            @include mq-max(sm){
              display: block;
              margin: 0 auto 20px;
            }
          }

          p{
            font-size: 1.2rem;
            @include mq-max(md){
              display: inline-block;
              margin-left: 30px;
              text-align: left;
            }
            @include mq-max(sm){
              max-width: 200px;
              display: block;
              margin: 0 auto;
            }
          }

        }

        //リンク（4カラム）
        .footerLink{
          display: flex;
          justify-content: space-between;

          //sp用の境界線
          .listDevide{
            @include mq-max(sm){
              border-bottom: 2px solid #fff;
              padding: 30px 0;
            }
          }

          &>[class*="col-"]{
            padding: 0;
            &>[class*="col-"]{
              padding: 0 45px;
              @include mq-max(lg){
                padding: 0 10px;
              }
              @include mq-max(md){
                padding: 30px 8px;
                margin: 0;
              }
              p{
                font-size: 1.2rem;
                margin: 0;
              }
              li{
                margin-bottom: 5px;
                &>ul{
                  margin-left: 1em;
                  margin-top: 5px;
                  li{
                    font-weight: normal;
                  }
                  &>ul{
                    margin-left: 1em;
                  }
                }
                a{
                  &:hover{
                    color: $keyColor;
                  }
                }
              }
            }
          }

          .grid{
            margin: 0;///gridのマージンを消すため
          }
        }

        &.grid{
          margin: 0;///gridのマージンを消すため
        }

      }

      //ico
      .ico{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include mq-max(sm){
          justify-content: center;
          margin-top: 20px;
        }
        img{
          display: inline-block;
          margin-right: 20px;
          &:last-child{
            margin-right: 0;
          }

          &.ryouritsu{
            width: 66px;
            height: 58px;
          }
          &.security{
            width: 58px;
            height: 66px;
          }
        }
      }
    }
  }





  //コピーライト
  .copyright{
    display: block;
    font-size: 1.2rem;
    text-align: center;
    color: #fff;
    padding: 15px 0;
    background-color: #0092E5;
  }
}
