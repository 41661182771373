
//文字の大きさ
.txt_small{
  line-height: 26px;
  font-size: 1.2rem;
}
.txt_medium{
  line-height: 28px;
  font-size: 1.8rem;
}
.txt_large{
  line-height: 37px;
  font-size: 2.4rem;
}

//文字の太さ
p{
  font-weight: 400;
}

.txt_bold{
  font-weight: 600;
}

ul{
  list-style-type: none;
}
