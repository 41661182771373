//メニューのPadding
$menuBar: 10px 35px;

#gnavi{
  //スマホの時はホーム消す
  #home{
    @include mq-max(sm){
      display: none;
    }
  }
  //スマホの時だけ表示
  .spLink{
    display: none;
    @include mq-max(sm){
      display: block;
    }

    //電話
    &.tel{
      a{
        background-color: #FF6E03;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        i{
          margin-right: 8px;
        }
        &>span{
          .time{
            font-size: 1.5rem;
            font-weight: 400;
            display: block;
          }
        }
      }
    }

    //コンタクトボタン
    &.contactBtn{
      display: none;
      margin: 0;
      @include mq-max(sm){
        display: flex !important;
        a{
          margin: 0;
          padding: 15px 0;
          border-bottom: none;
          i{
            display: block;
          }
          &.contact{
            background-color: $keyColor;
          }
        }
      }
    }
  }


  &>ul{
    margin-right: -15px;
    @include mq-max(sm){
      display: block;
      height: calc(100vh - 77.81px);//アドレスバー含む
      // height: 418px;
      background-color: transparent;
      overflow-y: scroll;
      display: none;
      margin-right: 0;
    }
    &>li{
      display: inline-block;
      @include mq-max(sm){
        display: block;
      }
      &>a{
        display: inline-block;
        padding: 0 15px 8px;
        font-size: 1.4rem;
        border-bottom: 1px solid #fff;

        @include mq-max(lg){
          padding: 0 8px 8px;
        }

        &:hover{
          border-bottom: 2px solid $keyColor;
          transition: border-bottom .3s;
          @include mq-max(sm){
            border-bottom: 1px solid #fff;//spでのhoverアクションを回避
          }
        }
        &.current{
          border-bottom: 2px solid $keyColor;
          @include mq-max(sm){
            border-bottom: 1px solid #fff;
          }
        }
        @include mq-max(sm){
          background-color: $subColor;
          padding: $menuBar;
          font-size: 1.4rem;
          color: #fff;
          display: block;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }


  //ドロップダウン
  .dropdownbnav{
    position: absolute;
    top: 91px;
    left: 0;
    width: 100%;
    background-color: rgba(74,175,237,0.7);
    padding: 33px 130px 40px 0;
    z-index: 999;
    justify-content: flex-end;
    display: none;//flex
    @include mq-max(sm){
      position: relative;
      top: 0;
      padding: 0;
      z-index: 1;
      justify-content: flex-start;
      flex-direction: column;
      &.active{
        // display: block;
      }
    }

    .nav_col{
      border-right: 1px solid #fff;
      @include mq-max(sm){
        border-right: none;
      }
      h3{
        color: #fff;
        font-size: 2.8rem;
      }
      li{
        display: block;
        a{
          color: #fff;
          font-size: 1.4rem;
          padding: 10px 40px;
          display: block;
          position: relative;
          @include mq-max(sm){
            font-size: 1.4rem;
            padding: $menuBar;
            padding-left: (35px + 18px);
            background-color: $subColor;
            border-bottom: 1px solid #fff;
          }
          &:hover{
            color: $keyColor;
            background-color: #fff;
            @include mq-max(sm){
              color: #fff;
              background-color: $subColor;
            }
            &:before{
              color: $keyColor;
              @include mq-max(sm){
                color: #fff;
              }
            }
          }
          &:before{
            content:"\f0da";
            font-family: "fontAwesome";
            color: #fff;
            position: absolute;
            display: block;
            font-size: 1.4rem;
            top: 50%;
            left: 20px;
            transform: translateY(-50%) translateX(-50%);
            @include mq-max(sm){
              display: none;
            }
          }
        }
        &>ul{
          padding-left: 1em;
          @include mq-max(sm){
            padding-left: 0;
          }
          li{
            @include mq-max(sm){
              // border-bottom: 1px solid #fff;
            }
            a{
              @include mq-max(sm){
                padding-left: (35px + 18px*2);
              }
              &:before{
                content: "-";
                @include mq-max(sm){
                  content: "-";
                  display: block;
                  left: 50px;
                }
              }
            }
          }
        }
      }

      &.first{
        padding: 0 30px;
        @include mq-max(sm){
          display: none;
        }
      }
      &.second{
        ul{
          width: 280px;
          @include mq-max(sm){
            width: 100%;
          }
        }
      }
      &.third{
        ul{
          width: 355px;
          @include mq-max(sm){
            width: 100%;
          }
        }
      }
    }
  }


  //SP用ドロップダウンアイコン
  .dropdown{
    @include mq-max(sm){
      &>a{
        position: relative;
        &:after{
          content: "\f067";
          font-family: "fontAwesome";
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
        }
        &.active{
          &:after{
            content: "\f068";
          }
        }
      }
    }
  }

}

//ハンバーガーメニュー
.toggleBtn{
  display: none;
@include mq-max(sm){
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  transition: all 0.3s;
  z-index: 10000;
  position: absolute;
  top: 5px;
  right: 10px;
  overflow: hidden;
  span{
      display: block;
      height: 4px;
      width: 40px;
      background: #000;
      border-radius: 10px;
      transition: all 0.3s;
      &:first-child{
          transform: translateY(-8px);
      }
      &:last-child{
          transform: translateY(8px);
      }
  }
  &.active span{
      &:first-child{
          transform: translateY(100%) rotate(45deg);
      }
      &:nth-of-type(2){
          transform: translateX(100%);
          opacity: 0;
      }
      &:last-child{
          transform: translateY(-100%) rotate(-45deg);
      }
  }
}
}