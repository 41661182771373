.news{
  h3.bigger{
    margin-bottom: 0;
    font-size: 4.2rem;
    // float: left;
    // @include mq-max(sm){
    //   float: none;
    // }
  }
  .news-all{
    text-align: right;
    margin-bottom: 20px;
    margin-right: 20px;
    // float: right;
    // @include mq-max(sm){
    //   float: none;
    // }
    a{
      font-weight: bold;
      &:after{
        font-family: 'fontawesome';
        content: "\f105";
      }
    }
    // @include mq-max(md){
    //   margin-right: 0;
    // }
  }
  .news-list{
    display: block;
    border: solid 3px #DEF4FD;
    padding: 40px 30px;
    // margin-right: 20px;
    @include mq-max(md){
      margin-right: 0;
    }
    h1,h2,h3,h4,h5{
      font-size: 1.8rem;
      color: $textColor;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    p{
      color: $textColor;
    }
    &:hover{
      opacity: 0.5;
    }

    .date{
      float: right;
    }
  }

  .grid{
    margin: 0;
    [class*='col-']{
      padding-bottom: 0;
      @include mq-max(sm){
        padding: 0 0px 20px;
        &:last-child{
          padding-bottom: 0;
        }
      }
    }
  }
}
