

#contents{
    a{
        color: $keyColor;
        &:hover{
            color: $keyColorDark;
        }

        //アイコン共通
        &:after{
            font-family: 'FontAwesome';
            font-size: 12px;
            line-height: 1.2;
            margin-left: 3px;
        }

        //アイコンなし
        &.ico_none{
            &:after{
                display: none;
            }
        }

        &[target="_blank"]:after{
            content: "\f24d";
        }
        &[href$=".pdf"]:after {
            content: "\f1c1";
            color: #e62727;
        }
    }
}


.anchor_link{
    background: $lightGreen;
    text-align: center;
    padding: 40px 0;
    margin-bottom: 100px;

    li{
        display: inline-block;
        margin-bottom: 1.5em;
        a{
            background: #fff;
            border: 3px solid;
            padding: 5px 15px;
            border-radius: 30px;
            line-height: 1;
            margin: 0 5px;
            &:before {
                font-family: 'fontawesome';
                position: relative;
                left: 0;
                margin-right: 8px;
                transition: left .2s;
                content: "\f107";
            }
            &:hover{
                color: $keyColorDark;
            }
        }
    }
}
