

//見出しの基本余白
$bottom_margin_midashi: 80px;
h1{
  margin: 0;
}
h2{
  margin-bottom: $bottom_margin_midashi;
  color: $keyColor;
  font-size: 9.2rem;
  font-weight: 600;
  line-height: 1;
  @include mq-max(md){
    font-size: 7rem;
  }
  @include mq-max(sm){
    font-size: 3.8rem;
    margin-bottom: 30px;
  }
  @include mq-max(xs){
    &.taC{
      text-align: left !important;//スマホの時だけ左寄せ
    }
  }
  &.lang-jp{//日本語設定
    font-size: 3.3rem;
    line-height: 1.5;
    @include mq-max(sm){
      font-size: 2.4rem;
    }
    + p{
      margin-top: -$bottom_margin_midashi + 25px;
      @include mq-max(sm){
        margin-top: 0
      }
    }
  }
  + p.txt_large{
      margin-top: -$bottom_margin_midashi + 20px;
      @include mq-max(sm){
        margin-top: -20px;
        font-size: 2rem;
      }
  }
  span.maincopy{
    display: inline-block;
    width: 70%;
    margin: 0;
    @include mq-max(sm){
      width: 100%;
    }
  }

  //小見出し
  span.subcopy{
    width: 30%;
    vertical-align: middle;
    font-style: italic;
    font-size: 2.4rem;
    font-weight: 400;
    font-style: normal;
    color: #000;
    display: inline-block;
    margin: auto;
    line-height: 1.5;
    @include mq-max(sm){
      font-size: 2rem;
      width: 100%;
    }
  }

}
h3{
  font-size: 2.8rem;
  margin-bottom: $bottom_margin_midashi - 50px;
  @include mq-max(xs){
    &.taC{
      text-align: left !important;
    }
  }
  &.bigger{
    font-size: 3rem;
    color: $keyColor;
    @include mq-max(xs){
      text-align: left;
      // padding: 0 20px;
    }
  }
  &.small{
    font-size: 2.2rem;
    color: $keyColor;
  }
}
h4{
  font-size: 2rem;
  color: $keyColor;
  margin-bottom: $bottom_margin_midashi - 50px - 5px;
  @include mq-max(xs){
    &.taC{
      text-align: left !important;
    }
  }
}
h5{
  font-size: 1.4rem;
  color: $keyColor;
  margin-bottom: $bottom_margin_midashi - 50px - 5px - 5px;
  @include mq-max(xs){
    &.taC{
      text-align: left !important;
    }
  }
}


//小文字付きh2
.section{
  section{
    &.with_subTitle{
      margin-bottom: 100px;
      h2{
        font-size: 3.3rem;
        line-height: 1;
        margin-bottom: 55px;
        span{
          display: block;
          font-size: 2.2rem;
          margin-top: 10px;
          line-height: 1;
        }
      }
    }
  }
}

