.accordion {
  transition: 0.4s;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.accordion-container {
  background-color: #fff;
  box-shadow: none;
  margin: 0 auto;
  @include mq-max(sm){
    width: 100%;
  }
  @include mq-max(xs){
    width: 100%;
  }
  .accordion {
      text-align: center;
      padding: 0.5em 0;
      background-color: #F1F1F1;
      position: relative;
      font-size: 18px;
      font-weight: bold;
      &:after{
        content: '\002B';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: #000;
        font-weight: bold;
        font-size: 30px;
        }
      }
    .active{
      &:after{
        content: "\2212";
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: #000;
        font-weight: bold;
        font-size: 30px;
      }
      /*ホバー・アクティブ色変える
      .active,
      .accordion:hover {
          background-color: darken(#ccc, 15%);
      }*/
    }
  .panel {
      transition: max-height 0.2s ease-out;
      background-color: white;
      max-height: 0;
      overflow: hidden;
  }
}
