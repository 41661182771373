header{
  position: relative;

  //ロゴ周り
  .headerArea{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    @include mq-max(sm){
      flex-direction: column;
      position: fixed;
      top: 0;
      background-color: #fff;
      z-index: 9999;
    }
    .headerLogo{
      display: flex;
      align-items: center;
      font-size: 0;
      @include mq-max(sm){
        border-bottom: 10px solid $keyColor;
      }
      .logo{
        display: inline-block;
        width: 126px;
        margin: 25px 0 20px 25px;
        line-height: 1;
        @include mq-max(md){
          width: 120px;
          margin: 15px 0 15px 25px;
        }
        img{
          width: 100%;
        }
      }
    }

    //右側メニュー
    .header_navi{
      //メニュー
      .linkArea{
        float: left;
        // height: 100%;
        // position: relative;
        @include mq-max(sm){
          float: none;
        }

        //採用情報・電話番号
        .subnavi{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 10px 0 14px;
          @include mq-max(sm){
            justify-content: flex-start;
            display: none;
          }
          li{
            margin-right: 50px;
            position: relative;
            &:last-child{
              margin-right: 0;
            }
            &.recruit{
              font-size: 1.2rem;
              line-height: 1;
              &:after{
                content: "";
                display: inline-block;
                position: absolute;
                background-color: #000;
                width: 1px;
                height: 14px;
                right: -25px;
              }
              i{
                margin-right: 8px;
                color: $subColor;
              }
            }
            &.tel{
              font-size: 1.8rem;
              display: flex;
              align-items: center;
              font-weight: bold;
              i{
                margin-right: 4px;
                color: #000;
              }
              span.time{
                font-size: 1.2rem;
                margin-left: 15px;
                font-weight: normal;
              }
            }
          }
        }
      }

      //無料相談会＆お問い合わせボタン
      .contactArea{
        margin-right: 0;
        margin-left: 45px;
        float: right;
        @include mq-max(lg){
          margin-left: 25px;
        }
        @include mq-max(sm){
          display: none;
        }
        .btnBox{
          width: 107px;
          height: 100%;
          padding: 20px 0;
          margin: 0;
          @include mq-max(sm){
            width: 71px;
          }
          i,span{
            display: block;
            text-align: center;
            color: #fff;
          }
          i{
            font-size: 2.8rem;
            margin-bottom: 5px;
            @include mq-max(sm){
              font-size: 1.8rem;
            }
          }
          span{
            font-size: 1.2rem;
            @include mq-max(sm){
              font-size: 0.9rem;
            }
          }
          &.consult{
            background-color: $subColor;
          }
          &.contact{
            background-color: $keyColor;
          }
        }

      }
    }
  }

}
